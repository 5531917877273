// variables
$bg-color: #fafcfa;
$text-color: #212121;
$box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 16%);

// css
.react-tooltip {
  background: $bg-color  !important;
  padding: 20px !important;
  border-radius: 10px !important;
  color: $text-color  !important;
  box-shadow: $box-shadow;

  &.place-bottom {
    &::after {
      border-width: 12px !important;
      top: -12px !important;
      margin-left: 0 !important;
      left: calc(8% - 6px) !important;
    }
  }

}