//
// Common classes
//

body.disable-scroll {
  overflow: hidden;
}

.max-w-80per {
  max-width: 80% !important; 
}