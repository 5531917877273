//
// Connect Processor
//

.c-modal {
    &.connect-processor {
        .items{
            .item{
                background-color: #f7f7f7;
                display: flex;
                flex-direction: column;
                border-radius: 0.42rem;
                box-shadow: 0px 0px 1px 0px rgba(82, 63, 105,0.5);
                padding: 2rem 2.25rem;
                align-items: center;
                margin: 12px 0;
                &:hover{
                    box-shadow: 0px 0px 6px 0px rgba(82, 63, 105,0.5);
                }
                .img{
                    border-radius: 100%;
                    width: 100px;
                    height: 100px;
                    margin: 0 auto 1.85714286em auto;
                    background: #fff url() center no-repeat;
                    background-size: contain;
                    display: flex;
                }
            }
        }
    }
}
