//
// left menu
//

.aside-left {
  .brand-logo {
    width: 100%;
    img {
      max-width: 90%;
      max-height: 35px;
    }
  }
}
