//
// react-json-pretty
//

.__json-pretty__{
  margin: 8px;
  .__json-key__{
    color: brown;
  }
  .__json-value__{
    color: green;
  }
  .__json-string__{
    color: #333399;
  }
}