//
// pages
// company-processors

// variables
$c-table-attempts-height: calc(100vh - 66px);
$pat-c1: rgba(77, 89, 149, 0.06); // gray

// mixins
@mixin processors-css-scroll {
  &::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    opacity: 1;
  }
  &::-webkit-scrollbar-thumb {
    background: #e0e2f0;
    border-radius: 5px;
    opacity: 1;
  }
  &::-webkit-scrollbar-thumb:hover > {
    background: #e0e2f0;
    opacity: 1;
  }
}

// CSS
.page {
  &.company-processors {
    //p-section
    .p-section {
      padding: 22px 0 0 0;
    }

    //list
    .list {
      //processors
      .processors {
        display: flex;
        max-width: 100%;
        overflow-y: auto;
        @include processors-css-scroll;
        .processor {
          border-radius: 5px;
          background: #fff;
          width: 73px;
          height: 73px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 7px 19px 0;
          font-size: 8px;
          line-height: 1;
          position: relative;
          z-index: 0;
          border: 1px solid white;
          .img {
            width: 37px;
            height: 37px;
            border-radius: 100%;
            margin: 10px auto 10px auto;
            background: #fff url() center no-repeat;
            background-size: contain;
            display: flex;
          }
          button {
            position: absolute;
            bottom: -19px;
            left: 6px;
            right: 6px;
            background-color: #b3d8ff;
            color: #007bff;
            text-align: center;
            font-size: 8px;
            font-weight: 500;
            height: 15px;
            border-radius: 8px;
            padding: 0;
            line-height: 15px;
          }
          .dropdown {
            position: absolute;
            right: 0;
            top: 3px;
            .btn-icon {
              height: 12px;
              width: 24px;
            }
            .ki {
              font-size: 12px;
            }
            .dropdown-menu{
              padding: 0;
              z-index: 1;
              min-width: 70px;
              transform: none !important;
              right: 0 !important;
              left: auto !important;
              top: 12px !important;
              li{
                .btn{
                  display: block;
                  text-align: left;
                  padding: 3px 6px;
                  font-size: 11px;
                  font-weight: 500;
                  line-height: 1.2;
                  color: #464E5F;
                }
                border-bottom: 1px solid #ECF0F3;
                &:last-child{
                  border-color: transparent;
                }
              }
            }
          }
          &.default {
            border-color: #007bff;
          }
          &.active {
            cursor: pointer;
            &:hover {
              border-color: #007bff;
            }
          }
          &.inactive {
            opacity: 0.5;
          }
        }
      }

      
    }
  }
}