.react-password-checklist {
  li {

    padding: 1px 0;
    
    .icon {
      font-size: 18px;
      font-weight: 500;
      padding-right: 4px;
      line-height: 1;
    }

    span {
      opacity: 0.7 !important;
      padding-top: 0 !important;
    }

    &.valid {
      span {
        opacity: 1 !important;
      }
    }

  }
}