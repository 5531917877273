//
// header mobile
//

.header-mobile {
  img {
    max-width: 200px;
    max-height: 35px;
  }
}
