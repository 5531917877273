/* Settings Page */
.wizard-step {
  cursor: pointer;
}

/* Payment-01 Page */
.input-group.with-prepend .rounded-lg {
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
}
.input-group.with-append .rounded-lg {
  border-top-right-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}
.input-group-payment-form {
  border: none;
}

.center-vertical-horizontal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.center-vertical-horizontal a {
  display: block;
  margin: 0 auto;
}
.payment-aside {
  position: relative;
  height: 100%
}
.center-horizontal-bottom {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -90%);
  width: 100%
}

.display-inline {
  display: inline-block!important;
}

@media (max-width: 1030px) {
  .center-vertical-horizontal {
    position: inherit;
  }
  .hide-mobile {
    display: none!important;
  }
}
@media (min-width: 1030px) {
  .hide-desktop {
    display: none;
  }
}

/* Payment-02 Page */
.input-group.with-prepend .rounded-pill {
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
}
.input-group.with-append .rounded-pill {
  border-top-right-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}
.input-group-payment-form {
  border: none;
}
.input-group-prepend.input-group-pill .input-group-payment-form {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
}
.input-group-append.input-group-pill .input-group-payment-form {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}

.jp-card .jp-card-front, .jp-card .jp-card-back {
  background: url('../media/card-bg.jpg')!important;
  background-size: cover!important;
}


/* Requests */
#payment-requests table {
  overflow-x: auto;
}
.datatable.datatable-default > .datatable-pager > .datatable-pager-nav > li > .datatable-pager-link {
  border-radius: 100%;
}

.splink-layout-box {
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover!important;
}

/* Custom Data Capture Form */

.btn-primary-active {
    color: #FFFFFF!important;
    background-color: #187DE4!important;
    border-color: #187DE4!important;
}

.btn-primary-active i {
    color: #FFFFFF!important;
}

.hidden {
  display: none!important;
}

.custom-cb-1 .checkbox {
    padding-left: 40px;
    padding-top: 5px;
}

/* Shop Form */
.shop-image-link-1 {
  border-radius: 20px
}
@media (max-width: 800px) {
  .shop-image-link-1 {
    display: block;
    margin: 5px auto;
    border-radius: 20px
  }
}

.carousel-cell {
  width: 66%;
  height: 160px;
  margin-right: 10px;
  border-radius: 5px;
  counter-increment: carousel-cell;
  margin-top: 10px;
}

/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  line-height: 200px;
  font-size: 80px;
  color: white;
}
.product-card {
  background: none;
}
.product-card a {
  margin: 0;
}

.flickity-page-dots .dot.is-selected {
  background: #FFF !important;
}

.selected-product {
    height: 170px;
    margin-top: 0px;
}

.selected-product .card-body {
  border: 2px solid #3699FF;
}
.select-custom .bootstrap-select > .dropdown-toggle.btn-light {
  background: none!important;
  border-radius: 50rem!important;
  height: 40px!important;
}
.select-custom .bootstrap-select {
  padding: 0px!important;
  color: #FFF;
}

.select-custom .bootstrap-select > .dropdown-toggle:after {
  color: #FFF;
  font-size: 15px;
  padding-right: 7px;
}

.select-custom .bootstrap-select > .dropdown-toggle.btn-light .filter-option {
  color: #FFF;
}

.select-custom-filled .bootstrap-select > .dropdown-toggle.btn-light {
  background: none!important;
  border-radius: 5rem!important;
  height: 40px!important;
  border: none;
}

.select-custom-filled .bootstrap-select {
  padding: 0px!important;
}

.select-custom-filled .bootstrap-select > .dropdown-toggle:after {
  font-size: 15px;
  padding-right: 7px;
}

.select-custom-filled .filter-option-inner-inner {
  padding-top: 3px;
}

/* Start TABLE Styles */
.ReactTable {
  border: none;
}

.Table__pagination .switchPageButton:hover,
.Table__pageButton:hover,
.Table__pageButton--active {
  background: #3699FF
}

.rt-td {
  color: #6c7293;
  padding: 0;
}

.rt-tr-group:hover {
  background-color: #fafbfc;
}

.-sort-desc,
.-sort-asc {
  box-shadow: none !important;
}

.rt-thead .rt-resizable-header-content {
  color: #B5B5C3 !important;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.rt-thead .-sort-asc .rt-resizable-header-content,
.rt-thead .-sort-desc .rt-resizable-header-content {
  color: #3699FF !important;
}

.rt-thead .rt-th {
  padding: 0.75rem 1rem !important;
}

.-sort-asc .rt-resizer::before {
  content: "\2191";
  color: #3699FF;
  vertical-align: middle;
  font-weight: 600;
}

.-sort-desc .rt-resizer::after {
  content:"\2193";
  color: #3699FF;
  vertical-align: middle;
  font-weight: 600;
}

.ReactTable .rt-resizer {
  top: 5px;
}

.ReactTable .rt-td {
  align-self: center;
}

.Table__page .select-page:hover {
  background-color: #3699FF;
}

.rt-resizable-header-content {
  color: #595d6e !important;
  font-weight: 500;
}

.ReactTable .rt-expander:after {
  border-top: 7px solid #3699FF !important;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border: 0 !important;
}

.ReactTable .rt-thead.-header {
  padding-bottom: 0 !important;
}

.ReactTable .rt-tr{
  padding-top: unset !important;
  padding-bottom: unset !important;
  overflow-x: auto;
}

.ReactTable .rt-tbody .rt-td {
  border-right: unset !important;
}

.ReactTable .switchPageButton,
.ReactTable .Table__pageButton {
  border-radius: 50% !important;
  font-size: unset !important;
}
/* End TABLE Styles */

.react-tel-input .form-control {
  width: 100% !important;
}

.react-share__ShareButton {
  padding-right: 5px !important;
}
.side-margin-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.aside-minimize .side-margin-20 {
  margin-left: 4px;
  margin-right: 4px;
  padding-left:2px !important;
  padding-right:2px !important;
}

/* starts, login-1 page fixed login-aside */
.login-1{}
@media (min-width: 992px){
  .login-1 .login-aside{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 1025px) {
  .login-1{
    padding-left: 600px;
  }
}
@media (max-width: 1399.98px) and (min-width: 992px){
  .login-1{
    padding-left: 450px;
  }
}
/* end, login-1 page fixed login-aside */

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 100% 800px;
  display: block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

span.shine:empty:before {
  content: "\200b";
}

.Toastify__toast--success {
  background-color: #3699FF !important;
  text-align: center;
}

.Toastify__toast--error {
  background-color: #fa6160 !important;
  text-align: center;
}

.custom-async-select__container{
  width: 100% !important;
  min-width: 100% !important;
  background-color: #F3F6F9 !important;
  border: 1px solid #E5EAEE !important;
  color: #3F4254 !important;
  border-radius: 0.42rem !important;
  font-size: 1rem !important;
}

.custom-async-select__control{
  background-color: unset !important;
  border-color: unset !important;
  border-radius: unset !important;
  border-style: unset !important;
}

.custom-async-select__menu{
  max-width: 100% !important;
}

.react-tel-input .form-control{
  background: #F3F6F9 !important;
}

.ReactTable .rt-tbody {
  min-height: 50px;
}

.ReactTable .rt-noData {
  top: 80px !important;
}

.modal-50 {
  width: 50% !important;
  max-width: 50% !important;
}

.modal-70 {
  width: 70% !important;
  max-width: 70% !important;
}

.image-search-thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
  margin-bottom: 4px;
}
.image-search-thumbnail-text {
  white-space:nowrap;
  overflow:hidden;
  padding-left: 4px;
  font-weight: 200;
  font-size: 12px;
}

.cropper-modal {
  background-color: #f1f1f1;
}

.kt-avatar__holder.bg-pos{
  background-position: 50% 50% !important;
}

.ReactTable .rt-tbody {
  min-height: 50px;
}

.ReactTable .rt-noData {
  top: 80px !important;
}

.background .image-input-wrapper{
  background-position: center;
}

.pull-right {
    float: right;
}

.c-selectbox{
  padding-left: 0 !important;
}
.c-selectbox div[class$="-control"] {
  border: 0;
  box-shadow: none;
  padding-left: 18px;
}

.c-selectbox{
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 0.42rem !important;
}
.c-selectbox div[class$="-control"] {
  background: transparent;
  border: 0;
  box-shadow: none;
  padding-left: 3px;
}
.c-selectbox div[class$="-ValueContainer"] {
  padding: 0 10px !important;
}
.c-selectbox div[class$="-placeholder"] {
  color:#B5B5C3;
}

@media (min-width: 992px){
  .aside-fixed .aside{
    overflow: auto;
  }
}

.input-group-append-bg-dark-error span {
  background: #fa6160;
  color: white;
}


/* starts, domain settings */
ul.nameservers{
  list-style: none;
  padding: 0;
  margin-top: 16px;
}
ul.nameservers li{
  padding: 1px 0;
}
.nameserver{
  margin-bottom: 16px;
}
.nameserver .name{
  min-width: 200px;
}
.nameserver .btn-outline-dark:not(:hover){
  color: #333333;
}
.nameserver.b-color{
  border-color: #adadad;
}
ul.nameservers,
.nameserver{
  border: 1px solid #ebedf2;
  padding: 12px;
  border-radius: 4px;
  background: #f2f3f8;
  color: #111;
  font-weight: 400;
  font-size: 14px;
}
/* end, domain settings */

.disable-view {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Poppins, Helvetica, sans-serif;
  text-align: center;
  font-size: 28px;
  color: #333;
  font-weight: 600;
}

.disable-view .logo-svg {
  width: 240px;
  height: 240px;
  top: 31px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.disable-view-absolute {
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
}

/* starts, alert */
.alert-button button {
  margin-top: 8px;
  background: transparent;
  color: white;
  border: 1px solid white;
  padding: 6px 20px;
}

.alert-button button:hover {
  background-color: white;
  color: #ffb822
}
/* end, alert */

.disabled .col-form-label {
  color: #c5c5c5;
}

.association-selector {
  width: 200px !important;
}
.tooltip-password {}

/*** Sub-Modal ***/
.sub-modal {
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.15s linear;
}

.text-grey {
  color: #80808F;
}

.payment-attempts .custom-async-select__container {
  background-color: transparent !important;
}
