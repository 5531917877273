// mixins
@mixin statistics-css-scroll {
  &::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    opacity: 1;
  }
  &::-webkit-scrollbar-thumb {
    background: #e0e2f0;
    border-radius: 10px;
    opacity: 1;
  }
  &::-webkit-scrollbar-thumb:hover > {
    background: #e0e2f0;
    opacity: 1;
  }
}

&.header {
  .statistics {
    max-height: 60px;
    margin: auto 16px auto 0;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    @include statistics-css-scroll;
    .items {
      display: flex;
      flex-wrap: nowrap; 
      .data {
        border: 0;
        margin: 5px 2px;
        padding: 6px;
        font-weight: 500;
        max-width: 100%;
        min-width: auto;
        text-align: center;
        width: 100%;
        transition: all 0.5s ease;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        &.btn-secondary {
          background-color: rgba(77, 89, 149, 0.06);
          color: #007bff;
        }
        .name {
          display: block;
          font-size: 11px;
          font-weight: 400;
        }
      }
    }
  }
}
